// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/categories';

export default {
  async getOfferCategories() {
    const response = await requestService.get(`${prefix}`);
    return response?.data?.data;
  },
  async addOfferCategories(payload) {
    const response = await requestService.post(`${prefix}`, payload, {}, false);
    return response?.data?.data;
  },
  async deleteOfferCategories(categoryId) {
    const response = await requestService.delete(`${prefix}/${categoryId}`);
    return response?.data?.data;
  }
};
