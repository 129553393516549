<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :persistent="changed"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        <v-row v-if="!$vuetify.breakpoint.smAndDown" no-gutters justify="space-between">
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
            type == "create" ? $t("home.create_offer") : $t("home.edit_offer")
          }}</span>
        </v-row>
        <v-slide-x-reverse-transition>
          <v-menu
              offset-y
              v-if="type == 'edit'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="cursor-pointer"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  small
              >
                <v-icon color="grey lighten-1" dense>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list v-if="permissions.can_add_offer" class="pa-0" dense>
              <v-list-item
                  @click="openDeleteOfferDialog(inputOffer)"
                  link
                  class="pa-0"
              >
                <v-row no-gutters>
                  <v-btn
                      text
                      class="pl-0"
                  >
                    <v-icon class="mx-2" color="red lighten-1" dense>
                      mdi-delete
                    </v-icon>
                    {{ $t("btn.remove_offer") }}
                  </v-btn>
                </v-row>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-slide-x-reverse-transition>
      </v-card-title>
      <v-card-text class="pb-5">
        <div>
          <v-row>
            <v-col cols="12">
              <p-carousel v-if="inputOffer.images" :images="inputOffer.images" @removeImage="removeImage"
                          :type="'addEdit'" class="mb-3"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputOffer.name"
                  :label="$t('form.title')"
                  :maxlength="25"
                  hide-details="auto"
                  :error-messages="nameErrors"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputOffer.crm_id"
                  :label="'CRM PRODUCT ID'"
                  :maxlength="6"
                  hide-details="auto"
                  :error-messages="CRMErrors"
                  @keypress="validationForNumbers"
                  @paste="validationForNumbers"
              />
            </v-col>
            <v-col cols="12">
              <v-row v-if="inputOffer.payout_model == 'cpa'">
                <v-col class="ml-3">
                  {{ $t("form.prices") }}
                </v-col>
              </v-row>
              <v-row v-if="inputOffer.payout_model == 'cpa'" align="center">
                <v-col class="d-flex align-center ma-0 pa-0 ml-3">
                  <v-col cols="6" class="pt-1">
                    <v-text-field
                        v-model="newPrice.price"
                        :placeholder="$t('settings.price')"
                        dense
                        class="activity-message-field"
                        outlined
                        style="font-size: 14px"
                        hide-details="auto"
                        color="primary"
                        :maxLength="9"
                        @keypress="validationForPrice($event)"
                        @paste="validationForPrice($event)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        v-model="newPrice.payout"
                        :placeholder="$t('form.payment')"
                        dense
                        class="activity-message-field"
                        outlined
                        style="font-size: 14px"
                        hide-details="auto"
                        color="primary"
                        :maxLength="9"
                        @keypress="validationForPrice($event)"
                        @paste="validationForPrice($event)"
                    >
                      <template v-slot:append-outer class="my-0">
                        <v-scroll-x-transition>
                          <v-btn
                              icon
                              style="position: relative; bottom: 2px; right: 6px"
                              :disabled="!isFormPriceValid"
                              @click="addNewItem('prices')"
                          >
                            <v-icon color="primary" transition="scale-transition">
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </v-scroll-x-transition>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-col>
              </v-row>
              <v-row v-if="(prices.length || inputOffer?.prices?.length) && inputOffer.payout_model == 'cpa'">
                <v-col
                    cols="12"
                    class="pt-2 pb-0"
                    style="max-height: 300px; overflow-y: auto;"
                >
                  <v-row v-for="(item, index) in type === 'create' ? prices : inputOffer?.prices" :key="index"
                         class="align-center px-4 py-2" style="border: 1px solid #eee;">
                    <v-col cols="1">
                      <v-btn
                          :disabled="(!item.price || !item.payout) || (!item.price && !item.payout) || (item.price < item.payout)"
                          icon @click="editingIndexPrices = editingIndexPrices === index ? null : index">
                        <v-icon :color="editingIndexPrices !== index ? 'blue' : 'grey'">mdi-pencil</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col v-if="editingIndexPrices !== index" cols="auto" class="d-flex"
                           :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'">
                      <div class="font-weight-bold pr-3">
                        {{ $t('settings.price') }} {{ ':' }}
                      </div>
                      {{ item.price }}
                    </v-col>

                    <v-col v-if="editingIndexPrices !== index" cols="auto" class="d-flex"
                           :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'">
                      <div class="font-weight-bold pr-3">
                        {{ $t('form.payment') }} {{ ':' }}
                      </div>
                      {{ item.payout }}
                    </v-col>

                    <v-col v-if="editingIndexPrices == index" cols="5" class="pt-1">
                      <v-text-field
                          v-model="item.price"
                          :placeholder="$t('settings.price')"
                          dense
                          class="activity-message-field"
                          outlined
                          style="font-size: 14px"
                          hide-details
                          color="primary"
                          :maxLength="9"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                          @blur="validatePrice(item)"
                          :rules="[value => !!value || 'Ціна не може бути порожнім']"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col v-if="editingIndexPrices == index" cols="5" class="pt-1">
                      <v-text-field
                          v-model="item.payout"
                          :placeholder="$t('offer.payment')"
                          dense
                          class="activity-message-field"
                          outlined
                          style="font-size: 14px"
                          hide-details
                          color="primary"
                          :maxLength="9"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                          @blur="validatePrice(item)"
                          :rules="[
                            value => !!value || 'Виплата не може бути порожнім',
                           ]"
                      >
                      </v-text-field>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="auto">
                      <v-btn icon x-small color="#db8a8a"
                             @click="removeItem('prices', type, item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  :items="payoutModels"
                  v-model="inputOffer.payout_model"
                  :item-value="'value'"
                  :item-text="'name'"
                  :label="'Тип виплати'"
                  dense
                  hide-details
                  outlined
                  disabled
                  :error-messages="payoutModelsErrors"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  :items="geoList"
                  v-model="inputOffer.geo_id"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('offersCatalog.geo')"
                  dense
                  outlined
                  hide-details="auto"
                  :error-messages="geoErrors"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  :items="categoriesList"
                  v-model="inputOffer.category_id"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('offersCatalog.category')"
                  dense
                  outlined
                  hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-3" style="font-size: 16px;">
              {{ $t("form.advertorials") }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="d-flex align-center ma-0 pa-0 ml-3">
              <v-col cols="6" class="pt-1">
                <v-text-field
                    v-model="newAdvertorial.name"
                    :placeholder="$t('form.title')"
                    dense
                    class="activity-message-field"
                    outlined
                    style="font-size: 14px"
                    hide-details
                    color="primary"
                    :maxLength="30">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="newAdvertorial.url"
                    :placeholder="'https://example.com'"
                    dense
                    class="activity-message-field"
                    outlined
                    style="font-size: 14px"
                    hide-details
                    color="primary"
                    :maxLength="100"
                >
                  <template v-slot:append-outer class="my-0">
                    <v-scroll-x-transition>
                      <v-btn
                          icon
                          style="position: relative; bottom: 2px; right: 6px"
                          :disabled="!isFormAdvertorialValid"
                          @click="addNewItem('advertorials')"
                      >
                        <v-icon color="primary" transition="scale-transition">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-scroll-x-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="advertorials.length || inputOffer?.advertorials?.length">
            <v-col
                cols="12"
                class="pt-2 pb-0"
                style="max-height: 300px; overflow-y: auto;"
            >
              <v-row v-for="(item, index) in type === 'create' ? advertorials : inputOffer?.advertorials" :key="index"
                     class="align-center px-4 py-2" style="border: 1px solid #eee;">
                <v-col cols="1">
                  <v-btn :disabled="(!item.name || !item.url) || (!item.name && !item.url)" icon
                         @click="editingIndexAdvertorials = editingIndexAdvertorials === index ? null : index">
                    <v-icon :color="editingIndexAdvertorials !== index ? 'blue' : 'grey'">mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col v-if="editingIndexAdvertorials !== index" cols="auto" class="font-weight-bold"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'">
                  {{ reducedName(item.name) }}
                </v-col>

                <v-col v-if="editingIndexAdvertorials !== index" cols="auto">
                  <v-btn :href="item.url" target="_blank" text class="pa-0"
                         style="text-decoration: underline; color: inherit;">
                    {{ reducedName(item.url) }}
                  </v-btn>
                </v-col>

                <v-col v-if="editingIndexAdvertorials == index" cols="5" class="pt-1">
                  <v-text-field
                      v-model="item.name"
                      :placeholder="$t('form.title')"
                      dense
                      class="activity-message-field"
                      outlined
                      style="font-size: 14px"
                      hide-details
                      color="primary"
                      :maxLength="30"
                      @blur="validateNotEmpty('advertorials', item)"
                      :rules="[value => !!value || 'Назва не може бути порожнім']"
                  >
                  </v-text-field>
                </v-col>

                <v-col v-if="editingIndexAdvertorials == index" cols="5" class="pt-1">
                  <v-text-field
                      v-model="item.url"
                      :placeholder="$t('form.title')"
                      dense
                      class="activity-message-field"
                      outlined
                      style="font-size: 14px"
                      hide-details
                      color="primary"
                      :maxLength="100"
                      @blur="validateNotEmpty('advertorials', item)"
                      :rules="[value => !!value || 'URL не може бути порожнім']"
                  >
                  </v-text-field>
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="auto">
                  <v-btn icon x-small color="#db8a8a"
                         @click="removeItem('advertorials', type, item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
            <v-row>
            <v-col class="ml-3" style="font-size: 16px;">
              {{ $t("offersCatalog.productPages_without_form") }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="d-flex align-center ma-0 pa-0 ml-3">
              <v-col cols="6" class="pt-1">
                <v-text-field
                    v-model="newProduct_page.name"
                    :placeholder="$t('form.title')"
                    dense
                    class="activity-message-field"
                    outlined
                    style="font-size: 14px"
                    hide-details
                    color="primary"
                    :maxLength="30">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="newProduct_page.url"
                    :placeholder="'https://example.com'"
                    dense
                    class="activity-message-field"
                    outlined
                    style="font-size: 14px"
                    hide-details
                    color="primary"
                    :maxLength="100"
                >
                  <template v-slot:append-outer class="my-0">
                    <v-scroll-x-transition>
                      <v-btn
                          icon
                          style="position: relative; bottom: 2px; right: 6px"
                          :disabled="!isFormProductPageValid"
                          @click="addNewItem('product_pages')"
                      >
                        <v-icon color="primary" transition="scale-transition">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-scroll-x-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="product_pages.length || inputOffer?.product_pages?.length">
            <v-col
                cols="12"
                class="pt-2 pb-0"
                style="max-height: 300px; overflow-y: auto;"
            >
              <v-row v-for="(item, index) in type === 'create' ? product_pages : inputOffer?.product_pages" :key="index"
                     class="align-center px-4 py-2" style="border: 1px solid #eee;">
                <v-col cols="1">
                  <v-btn :disabled="(!item.name || !item.url) || (!item.name && !item.url)" icon
                         @click="editingIndexProductPages = editingIndexProductPages === index ? null : index">
                    <v-icon :color="editingIndexProductPages !== index ? 'blue' : 'grey'">mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col v-if="editingIndexProductPages !== index" cols="auto" class="font-weight-bold"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'">
                  {{ reducedName(item.name) }}
                </v-col>

                <v-col v-if="editingIndexProductPages !== index" cols="auto">
                  <v-btn :href="item.url" target="_blank" text class="pa-0"
                         style="text-decoration: underline; color: inherit;">
                    {{ reducedName(item.url) }}
                  </v-btn>
                </v-col>

                <v-col v-if="editingIndexProductPages == index" cols="5" class="pt-1">
                  <v-text-field
                      v-model="item.name"
                      :placeholder="$t('form.title')"
                      dense
                      class="activity-message-field"
                      outlined
                      style="font-size: 14px"
                      hide-details
                      color="primary"
                      :maxLength="30"
                      @blur="validateNotEmpty('landings', item)"
                      :rules="[value => !!value || 'Назва не може бути порожнім']"
                  >
                  </v-text-field>
                </v-col>

                <v-col v-if="editingIndexProductPages == index" cols="5" class="pt-1">
                  <v-text-field
                      v-model="item.url"
                      :placeholder="$t('form.title')"
                      dense
                      class="activity-message-field"
                      outlined
                      style="font-size: 14px"
                      hide-details
                      color="primary"
                      :maxLength="100"
                      @blur="validateNotEmpty('product_pages', item)"
                      :rules="[value => !!value || 'URL не може бути порожнім']"
                  >
                  </v-text-field>
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="auto">
                  <v-btn icon x-small color="#db8a8a"
                         @click="removeItem('product_pages', type, item);">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml-3" style="font-size: 16px;">
              {{ $t("offersCatalog.productPages_with_form") }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="d-flex align-center ma-0 pa-0 ml-3">
              <v-col cols="6" class="pt-1">
                <v-text-field
                    v-model="newLanding.name"
                    :placeholder="$t('form.title')"
                    dense
                    class="activity-message-field"
                    outlined
                    style="font-size: 14px"
                    hide-details
                    color="primary"
                    :maxLength="30">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="newLanding.url"
                    :placeholder="'https://example.com'"
                    dense
                    class="activity-message-field"
                    outlined
                    style="font-size: 14px"
                    hide-details
                    color="primary"
                    :maxLength="100"
                >
                  <template v-slot:append-outer class="my-0">
                    <v-scroll-x-transition>
                      <v-btn
                          icon
                          style="position: relative; bottom: 2px; right: 6px"
                          :disabled="!isFormLandingValid"
                          @click="addNewItem('landings')"
                      >
                        <v-icon color="primary" transition="scale-transition">
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-scroll-x-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-col>
          </v-row>
          <v-row v-if="landings.length || inputOffer?.landings?.length">
            <v-col
                cols="12"
                class="pt-2 pb-0"
                style="max-height: 300px; overflow-y: auto;"
            >
              <v-row v-for="(item, index) in type === 'create' ? landings : inputOffer?.landings" :key="index"
                     class="align-center px-4 py-2" style="border: 1px solid #eee;">
                <v-col cols="1">
                  <v-btn :disabled="(!item.name || !item.url) || (!item.name && !item.url)" icon
                         @click="editingIndexLandings = editingIndexLandings === index ? null : index">
                    <v-icon :color="editingIndexLandings !== index ? 'blue' : 'grey'">mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col v-if="editingIndexLandings !== index" cols="auto" class="font-weight-bold"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'">
                  {{ reducedName(item.name) }}
                </v-col>

                <v-col v-if="editingIndexLandings !== index" cols="auto">
                  <v-btn :href="item.url" target="_blank" text class="pa-0"
                         style="text-decoration: underline; color: inherit;">
                    {{ reducedName(item.url) }}
                  </v-btn>
                </v-col>

                <v-col v-if="editingIndexLandings == index" cols="5" class="pt-1">
                  <v-text-field
                      v-model="item.name"
                      :placeholder="$t('form.title')"
                      dense
                      class="activity-message-field"
                      outlined
                      style="font-size: 14px"
                      hide-details
                      color="primary"
                      :maxLength="30"
                      @blur="validateNotEmpty('landings', item)"
                      :rules="[value => !!value || 'Назва не може бути порожнім']"
                  >
                  </v-text-field>
                </v-col>

                <v-col v-if="editingIndexLandings == index" cols="5" class="pt-1">
                  <v-text-field
                      v-model="item.url"
                      :placeholder="$t('form.title')"
                      dense
                      class="activity-message-field"
                      outlined
                      style="font-size: 14px"
                      hide-details
                      color="primary"
                      :maxLength="100"
                      @blur="validateNotEmpty('landings', item)"
                      :rules="[value => !!value || 'URL не може бути порожнім']"
                  >
                  </v-text-field>
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="auto">
                  <v-btn icon x-small color="#db8a8a"
                         @click="removeItem('landings', type, item);">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                  outlined
                  dense
                  color="primary"
                  v-model="inputOffer.description"
                  :label="$t('offersCatalog.description')"
                  rows="3"
                  :maxlength="1000"
                  :error-messages="descriptionErrors"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
                dark
                block
                class="br-5 text-transform-none"
                @click="showConfirmDialog"
            >{{ $t("btn.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
                v-if="type === 'edit'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onUpdate()"
            >{{ $t("btn.update") }}
            </v-btn>
            <v-btn
                v-if="type === 'create'"
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onCreate()"
            >{{ $t("btn.create") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onClose"
    />
    <deleteConfirmDialog
        :visible="deleteOfferDialog"
        @close="deleteOfferDialog = false"
        @delete="deleteOffer"
    />
  </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import {validationForNumbers, validationForPrice, validationForTitle} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import EventBus from "@/events/EventBus";
import tImgMixin from "@/mixins/tImgMixin";
import offersService from "@/services/request/offers/offersService";
import _ from "lodash";
import offersCatalogMixin from "@/components/offersCatalog/offersCatalogMixin";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import user from "@/mixins/user";

export default {
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputOffer: {},
    copyOffer: {},
    offer: {},
    geoList: [],
    categoriesList: [],
    landings: [],
    product_pages: [],
    advertorials: [],
    prices: [],
    payoutModels: [
      {name: 'CPA', value: 'cpa'},
      {name: 'Dropshipping', value: 'dropshipping'},
    ],
    newLanding: {
      name: "",
      url: ""
    },
    newProduct_page: {
      name: "",
      url: ""
    },
    newAdvertorial: {
      name: "",
      url: ""
    },
    newPrice: {
      price: "",
      payout: ""
    },
    deleteOfferDialog: false,
    deletedOffer: {},
    serverErrors: {},
    removedImages: [],
    editingIndexPrices: null,
    editingIndexAdvertorials: null,
    editingIndexProductPages: null,
    editingIndexLandings: null
  }),
  mixins: [dialogMixin, validationMixin, notifications, tImgMixin, offersCatalogMixin, user],
  validations: {
    inputOffer: {
      name: {required},
      description: {required},
      geo_id: {required},
      crm_id: {required},
      payout_model: {required},
    },
  },
  components: {
    DeleteConfirmDialog,
    ConfirmLeftDialog,
  },
  props: {
    type: {
      required: true,
    },
    selectedOffer: {
      required: false,
      default: () => ({}),
    },
  },
  async mounted() {
    this.onMounted();
  },
  methods: {
    validationForNumbers,
    validationForPrice,
    validatePrice(item) {
      if (!item.price && !item.payout) { this.removeItem('prices', this.type, item)};

      if (item.payout && item.price && parseFloat(item.payout) > parseFloat(item.price)) {
        this.setErrorNotification(this.$t('offer.payment_cant_be_more'))
      }
    },
    validateNotEmpty(name, item) {
      if (!item.name && !item.url) {
        this.removeItem(name, this.type, item);
      }
    },
    openDeleteOfferDialog(item) {
      this.deleteOfferDialog = true;
      this.deletedOffer = item;
    },
    removeImage(img) {
      this.removedImages.push(img.id);
    },
    removeItem(fieldType, type, deletedItem) {
      try {
        const targetArray = (type === "create" ? this[fieldType] : this.inputOffer[fieldType]);

        if (!targetArray) {
          console.error(`Invalid fieldType or type: ${fieldType}, ${type}`);
          return;
        }

        const isPricesField = fieldType === 'prices';
        const compareField = isPricesField ?
            (item, deletedItem) => item.price === deletedItem.price && item.payout === deletedItem.payout :
            (item, deletedItem) => item.name === deletedItem.name && item.url === deletedItem.url;

        const index = targetArray.findIndex(item => compareField(item, deletedItem));

        if (index !== -1) targetArray.splice(index, 1);

      } catch (e) {
        console.error("Error in removeItem:", e);
      }
    },
    deleteOffer() {
      this.$emit('deleteOffer', this.deletedOffer);
    },
    validationForTitle,
    async onMounted() {
      this.loading = true;
      await this.fetchData();
      if (Object.keys(this.selectedOffer).length) {
        this.offer = this.selectedOffer;
      } else {
        this.offer = {
          name: "",
          crm_id: "",
          payout_model: "cpa",
          geo_id: "",
          category_id: "",
          description: "",
          images: [],
        };
      }
      this.inputOffer = _.cloneDeep(this.offer);
      this.copyOffer = _.cloneDeep(this.offer);
      this.loading = false;
    },
    addNewItem(fieldType) {
      try {
        const fieldMapping = {
          landings: "newLanding",
          advertorials: "newAdvertorial",
          product_pages: "newProduct_page",
          prices: "newPrice",
        };

        const newItemKey = fieldMapping[fieldType];

        if (!newItemKey || !this[newItemKey]) {
          console.error(`Invalid fieldType: ${fieldType}`);
          return;
        }

        if (fieldType === "prices") {
          const payout = parseFloat(this.newPrice.payout);
          const price = parseFloat(this.newPrice.price);
          if (payout >= price) {
            return this.setErrorNotification(this.$t("offer.payment_cant_be_more_or_equal"));
          }
        }

        const newItem = { ...this[newItemKey] };

        if (this.type === "create") {
          this[fieldType].unshift(newItem);
        } else {
          this.inputOffer[fieldType].unshift(newItem);
        }

        Object.keys(this[newItemKey]).forEach((key) => {
          this[newItemKey][key] = "";
        });

      } catch (e) {
        console.error("Error in addNewItem:", e);
      }
    },
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onClose();
    },
    async fetchData() {
      await Promise.all([this.getGeo(), this.getCategories()]);
    },
    async onCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      try {
        const formData = this.prepareFormData();
        await offersService.addOffer(formData);

        this.setSuccessNotification(this.$t("home.successfully_created_offer"));
        EventBus.$emit('offerCreated');
        this.onClose();
      } catch (e) {
        this.setErrorNotification(e.error.description);
      } finally {
        this.loading = false;
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      try {
        const formData = this.prepareFormData(true);
        const modifiedOffer = await offersService.updateOffer(this.inputOffer.id, formData);

        this.copyOffer = { ...this.inputOffer };
        this.inputOffer = { ...this.copyOffer };
        this.$v.$reset();

        this.setSuccessNotification(this.$t("home.successfully_updated_offer"));
        this.$emit("offerModified", modifiedOffer);
        this.visibility = false;
      } catch (e) {
        if (e.response?.status === 422) {
          this.serverErrors = e.response.data.errors;
          this.mapServerErrorsToForm();
        } else {
          this.setErrorNotification(e.message);
        }
      } finally {
        this.loading = false;
      }
    },
    prepareFormData(isUpdate = false) {
      const formData = new FormData();
      if (isUpdate) formData.append("_method", "patch");

      const changedData = this.$getChangedData(this.inputOffer, this.copyOffer);
      changedData.forEach(({ field, value }) => {
        if (!["images", "landings", "prices", "advertorials", "product_pages"].includes(field)) {
          formData.append(field, value);
        }
      });

      this.appendFiles(formData, "images", this.inputOffer.images);
      if (isUpdate) this.appendFiles(formData, "remove_images", this.removedImages, true);

      this.appendEntities(formData, "landings", !isUpdate ? this.landings : this.inputOffer.landings);
      this.appendEntities(formData, "product_pages", !isUpdate ? this.product_pages : this.inputOffer.product_pages);
      this.appendEntities(formData, "advertorials", !isUpdate ? this.advertorials : this.inputOffer.advertorials);

      this.appendPrices(formData, "prices", !isUpdate ? this.prices : this.inputOffer.prices);

      formData.append("payout_model", this.inputOffer.payout_model);

      return formData;
    },
    appendFiles(formData, key, files, isNumber = false) {
      if (!files?.length) return;
      files.forEach(file => {
        if (isNumber ? typeof file === "number" : file instanceof File) {
          formData.append(`${key}[]`, file);
        }
      });
    },
    appendEntities(formData, key, entities) {
      if (!entities?.length) {
        formData.append(key, "");
        return;
      }
      entities.forEach((data, index) => {
        if (data.id) formData.append(`${key}[${index}][id]`, data.id);
        formData.append(`${key}[${index}][name]`, data.name);
        formData.append(`${key}[${index}][url]`, data.url);
      });
    },
    appendPrices(formData, key, prices) {
      if (!prices?.length) {
        formData.append(key, "");
        return;
      }
      prices.forEach((priceData, index) => {
        console.log(priceData)
        if (priceData.id) formData.append(`${key}[${index}][id]`, priceData.id);
        formData.append(`${key}[${index}][price]`, priceData.price);
        formData.append(`${key}[${index}][payout]`, priceData.payout);
      });
    },
    reducedName(item) {
      if (item.length > 30) {
        return item.substring(0, 30) + "...";
      }
      return item;
    },
    validateURL() {
      const urlPattern = /^(https?:\/\/)/;
      return urlPattern.test(this.newLanding.url) || urlPattern.test(this.newAdvertorial.url);
    },
    validateURLProduct() {
      const urlPattern = /^(https?:\/\/)/;
      return urlPattern.test(this.newProduct_page.url) || urlPattern.test(this.newProduct_page.url);
    },
    mapServerErrorsToForm() {
      Object.entries(this.serverErrors).forEach(([key, value]) => {
        if (this.$v.inputOffer[key]) {
          this.$v.inputOffer[key].$invalid = true;
          this.$v.inputOffer[key].serverError = value[0];
        }
      });
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.inputOffer.name.$dirty) return errors;
      !this.$v.inputOffer.name.required && errors.push(this.$t("form.errors.TitleRequired"));
      if (this.$v.inputOffer.name.serverError) {
        errors.push(this.$v.inputOffer.name.serverError);
      }
      return errors;
    },
    CRMErrors() {
      const errors = [];
      if (!this.$v.inputOffer.crm_id.$dirty) return errors;
      !this.$v.inputOffer.crm_id.required && errors.push(this.$t("form.errors.CRMRequired"));
      if (this.$v.inputOffer.crm_id.serverError) {
        errors.push(this.$v.inputOffer.crm_id.serverError);
      }
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.inputOffer.description.$dirty) return errors;
      !this.$v.inputOffer.description.required && errors.push(this.$t("form.errors.DescriptionRequired"));
      if (this.$v.inputOffer.description.serverError) {
        errors.push(this.$v.inputOffer.description.serverError);
      }
      return errors;
    },
    payoutModelsErrors() {
      const errors = [];
      if (!this.$v.inputOffer.payout_model.$dirty) {
        return errors;
      }
      !this.$v.inputOffer.payout_model.required &&
      errors.push(this.$t("form.errors.PayoutModelRequired"));
      return errors;
    },
    geoErrors() {
      const errors = [];
      if (!this.$v.inputOffer.geo_id.$dirty) return errors;
      !this.$v.inputOffer.geo_id.required && errors.push(this.$t("form.errors.geoRequired")); // Using the correct translation key
      return errors;
    },
    isFormLandingValid() {
      return this.newLanding.name.trim() !== '' && this.newLanding.url.trim() !== '' && this.validateURL();
    },
    isFormProductPageValid() {
      return this.newProduct_page.name.trim() !== '' && this.newProduct_page.url.trim() !== '' && this.validateURLProduct();
    },
    isFormAdvertorialValid() {
      return this.newAdvertorial.name.trim() !== '' && this.newAdvertorial.url.trim() !== '' && this.validateURL();
    },
    isFormPriceValid() {
      return this.newPrice.price.trim() !== '' && this.newPrice.payout.trim() !== '';
    },
  },
  watch: {
    inputOffer: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyOffer, this.inputOffer);
      },
    },
  },
};
</script>