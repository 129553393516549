import imagePath from "@/assets/images/bicycle.svg";
import offersService from "@/services/request/offers/offersService";
import geoService from "@/services/request/geo/geoService";
import offerCategoriesService from "@/services/request/offerCategories/offerCategoriesService";

export default {
  methods: {
    async getGeo() {
      try {
        this.loading = true;
        this.geoList = await geoService.getGeo();
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async getCategories() {
      try {
        this.loading = true;
        this.categoriesList = await offerCategoriesService.getOfferCategories();
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
  },
};
